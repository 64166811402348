import React from 'react';
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from 'react-icons/fa';
import { FaPlus } from "react-icons/fa";
import mobileServiceList from "../scripts/mobileServiceList";
import { Link } from 'react-router-dom';

const MobileServiceDetailsArea = () => {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_catagory">
                  <h5 className="widget-title">
                    <FaArrowRight /> All Service lists
                  </h5>
                  <ul className="catagory-items">
                    <li>
                      <Link to="/ui-ux-service-details"> UI/UX Design Services</Link>
                    </li>
                    <li>
                      <Link to="/web-design-service-details"> Web Design & Development Services</Link>
                    </li>
                    <li>
                      <Link to="/mobile-app-service-details"> Mobile App Development (Android & iOS)</Link>
                    </li>
                    <li>
                      <Link to="/digital-marketing-service-details"> Digital Marketing</Link>
                    </li>
                    <li>
                      <Link to="/seo-service-details">SEO (Search Engine Optimization)</Link>
                    </li>
                    {/* <li>
                      <Link to="/email-marketing-service-details">Email Marketing</Link>
                    </li> */}
                  </ul>
                </div>
                {/* <div className="widget widget_author text-center">
                  <div className="thumb">
                    <img src="assets/img/about/9.png" alt="img" />
                  </div>
                  <div className="details">
                    <Link className="btn btn-base border-radius-5" to="#">
                      Discover our company +
                    </Link>
                  </div>
                </div>
             */}
              </div>
            </div>
            <div className="col-lg-8">
              
                  <div className="thumb">
                    <img src="assets/img/service/mobileapp.jpg" alt="img" />
                  </div>
                  
                  </div>
                  <div className="col-lg-12">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="details">
                    {/* <h4>
                    Div Info System provides a range of UI/UX web design services
                    </h4>
                    <p>
                    Div Info System is the best company for making websites look and work great. We have a team of talented designers who know all the latest tools for making websites awesome. Our designers can do a lot of different things to make your website just the way you want it. </p>
                     */}
                    <div className='service-area bg-relative '>
        <img
          className='position-bottom-left top_image_bounce'
          src='assets/img/icon/4.png'
          alt='img'
        />
        <div className='container'>
          <div className='row'>
            {mobileServiceList.map((data, index) => (
              <div className='col-lg-4 col-md-6' key={index}>
                <div className='single-service-inner text-center'>
                  <div className='thumb'>
                    <img src={data.img} alt='img' />
                  </div>
                  <div className='details'>
                    <h5>
                      {/* <Link to='/service-details'> */}
                      {data.title}
                      {/* </Link> */}
                    </h5>
                    <p>{data.des}</p>
                   
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>  
                    {/* <h4>Design Excellence in Every Detail</h4> */}
                   
                    {/* <div className="row">
                      <div className="col-md-6">
                        <ul className="single-list-inner style-check style-check mb-3">
                          <li>
                            <FaCheckCircle /> Visual Design & Branding
                          </li>
                          <li>
                            <FaCheckCircle /> Wireframing & Prototyping
                            companies
                          </li>
                          <li>
                            <FaCheckCircle /> Interaction Design
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="single-list-inner style-check style-check mb-3">
                          <li>
                            <FaCheckCircle /> Creating a Balanced and Nutritious
                          </li>
                          <li>
                            <FaCheckCircle /> User Research & Personas
                            companies
                          </li>
                          <li>
                            <FaCheckCircle /> Accessibility Design
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default MobileServiceDetailsArea;
