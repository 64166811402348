import React from 'react';
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from 'react-icons/fa';
import { FaPlus } from "react-icons/fa";
import webServiceList from "../scripts/webServiceList";
import { Link } from 'react-router-dom';
import WebTechArea from './WebTechArea';

const WebServiceDetailsArea = () => {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_catagory">
                  <h5 className="widget-title">
                    <FaArrowRight /> All Service lists
                  </h5>
                  <ul className="catagory-items">
                    <li>
                      <Link to="/ui-ux-service-details"> UI/UX Design Services</Link>
                    </li>
                    <li>
                      <Link to="/web-design-service-details"> Web Design & Development Services</Link>
                    </li>
                    <li>
                      <Link to="/mobile-app-service-details"> Mobile App Development (Android & iOS)</Link>
                    </li>
                    <li>
                      <Link to="/digital-marketing-service-details"> Digital Marketing</Link>
                    </li>
                    <li>
                      <Link to="/seo-service-details">SEO (Search Engine Optimization)</Link>
                    </li>
                    {/* <li>
                      <Link to="/email-marketing-service-details">Email Marketing</Link>
                    </li> */}
                  </ul>
                </div>
                {/* <div className="widget widget_author text-center">
                  <div className="thumb">
                    <img src="assets/img/about/9.png" alt="img" />
                  </div>
                  <div className="details">
                    <Link className="btn btn-base border-radius-5" to="#">
                      Discover our company +
                    </Link>
                  </div>
                </div>
             */}
              </div>
            </div>
            <div className="col-lg-8">
              
                  <div className="thumb">
                    <img src="assets/img/service/webdevelop.jpg" alt="img" />
                  </div>
                  
                  </div>
                  <div className="col-lg-12">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="details">
                    {/* <h4>
                    Div Info System provides a range of UI/UX web design services
                    </h4>
                    <p>
                    Div Info System is the best company for making websites look and work great. We have a team of talented designers who know all the latest tools for making websites awesome. Our designers can do a lot of different things to make your website just the way you want it. </p>
                     */}
                    <div className='service-area bg-relative '>
        <img
          className='position-bottom-left top_image_bounce'
          src='assets/img/icon/4.png'
          alt='img'
        />
        <div className='container'>
          <div className='row'>
            {webServiceList.map((data, index) => (
              <div className='col-lg-4 col-md-6' key={index}>
                <div className='single-service-inner text-center'>
                  <div className='thumb'>
                    <img src={data.img} alt='img' />
                  </div>
                  <div className='details'>
                    <h5>
                      {/* <Link to='/service-details'> */}
                      {data.title}
                      {/* </Link> */}
                    </h5>
                    <p>{data.des}</p>
                   
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>  
      {/* <div className='work-process-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='section-title text-center pb-5'>
            <h6 className='color-base mb-3 bg-none'>Tech Stack</h6>
            <h2 className='title'>Technology Stack We Consider For Web Development</h2>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>React</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/13.png' alt='img' />
                </div>
                
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>Vue.js</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/14.png' alt='img' />
                </div>
                
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>Java</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/15.png' alt='img' />
                </div>
        
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>Python</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/16.png' alt='img' />
                </div>
              
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>Laravel</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/16.png' alt='img' />
                </div>
              
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>PHP</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/16.png' alt='img' />
                </div>
              
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>Angular JS</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/16.png' alt='img' />
                </div>
              
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>Wordpress</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/16.png' alt='img' />
                </div>
              
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>DotNet</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/16.png' alt='img' />
                </div>
              
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>Magento</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/16.png' alt='img' />
                </div>
              
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>Shopify</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/16.png' alt='img' />
                </div>
              
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-4 text-center'>
                <h5>Drupal</h5>
                <div className='thumb'>
                  <img src='assets/img/home-6/16.png' alt='img' />
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <WebTechArea/>
      <div className='work-process-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-7 col-lg-9'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>Our Process</h6>
                <h2 className='title'>
                How To Hire <span> Web Development</span> Team From <span>Div Info System</span>
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-3 text-center'>
                <h2 className='process-count mb-2 color-base'>01.</h2>
                <h5 className='mb-4'>Tell Us All About Your Project Needs</h5>
                <img src='assets/img/testimonial/step1.jpg' alt='img' />
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-3 text-center'>
                <h2 className='process-count mb-2 color-base'>02.</h2>
                <h5 className='mb-4'>Get Advice on the Best Way to Work Together</h5>
                <img src='assets/img/testimonial/step2.jpg' alt='img' />
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-3 text-center'>
                <h2 className='process-count mb-2 color-base'>03.</h2>
                <h5 className='mb-4'>Interviewing Candidates </h5>
                <img src='assets/img/testimonial/step3.jpg' alt='img' />
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='single-work-process-inner-3 text-center'>
                <h2 className='process-count mb-2 color-base'>04.</h2>
                <h5 className='mb-4'>Get Started on the Project</h5>
                <img src='assets/img/testimonial/step4.jpg' alt='img' />
              </div>
            </div>
          </div>
        </div>
      </div>
                    {/* <h4>Design Excellence in Every Detail</h4> */}
                   
                    {/* <div className="row">
                      <div className="col-md-6">
                        <ul className="single-list-inner style-check style-check mb-3">
                          <li>
                            <FaCheckCircle /> Visual Design & Branding
                          </li>
                          <li>
                            <FaCheckCircle /> Wireframing & Prototyping
                            companies
                          </li>
                          <li>
                            <FaCheckCircle /> Interaction Design
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="single-list-inner style-check style-check mb-3">
                          <li>
                            <FaCheckCircle /> Creating a Balanced and Nutritious
                          </li>
                          <li>
                            <FaCheckCircle /> User Research & Personas
                            companies
                          </li>
                          <li>
                            <FaCheckCircle /> Accessibility Design
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default WebServiceDetailsArea;
