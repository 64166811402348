import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBarThree from "../components/NavbarThree";
import MobileServiceDetailsArea from "../components/MobileServiceDetailsArea";
import ProjectAreaThree from "../components/ProjectAreaThree";
import MobileServiceArea from "../components/MobileServiceArea";
import ServiceAreaGroupFive from "../components/ServiceAreaGroupFive";

const MobileServiceDetails = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarThree />

      {/* Navigation Bar */}
      <Breadcrumb title={"Mobile App Development (Android & iOS)"} />

      {/* Service Details Area */}
      <MobileServiceDetailsArea />
<ProjectAreaThree/>

<ServiceAreaGroupFive/>

<MobileServiceArea/>

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default MobileServiceDetails;
