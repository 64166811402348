import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";

const UiServiceArea = () => {
  return (
    <>
      {/*=================== service area five start ===================*/}
      <div className="service-area service-area_5 bg-gray bg-relative pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6 className="sub-title">Our Best Service</h6>
                <h2 className="title">
               <span>Benefits</span>  of Choosing <span>Div Info System</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row custom-no-gutter">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-3 single-service-inner-3-left">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/improved.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Improved Access and Readability
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  Our design experts use systems to organize content that make your website easy to see, consistent, and user-friendly.
                  </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-left">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/bestperformance.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Best Performance
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  How well your website works affects everything, even how easily people find it on search engines. 
                  </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-left mb-0">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/lighterandinteresting.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Lighter, More Interesting Pages
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  In today's world, it's important to find a balance between making things look good and making them work well. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 bg-blue-right d-lg-inline-block d-none">
              <div className="service-thumb service-middle-section align-self-end">
                <img src="assets/img/about/mobileapp.png" alt="img" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-3 single-service-inner-3-right">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/business.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Understanding Your Business
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  UI/UX design tries to understand how people think and use your website. However, getting useful feedback from users is hard.
                  </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-right">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/cleanwebsite.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Neat and Well-Ordered Website
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  A strong and flexible structure for the website is like its backbone because it controls how everything works.
                  </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-right mb-0">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/brandawareness.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Create Brand Awareness
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  In today's super competitive market, you can't use dull and ordinary designs. If you want to be noticed, you need a great UI .
                  </p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default UiServiceArea;
