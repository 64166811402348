import React from 'react';
import { FaCheck, FaCross } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const PricingAreaTwo = () => {
  return (
    <>
      {/* Pricing Area Two start */}
      <div id='pricing' className="pricing-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">Pricing plan</h6>
            <h2 className="title">
              Best plans to get our <span>Product</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing-inner">
                <div className="price-header text-center mb-lg-4">
                  <h5>Basic</h5>
                  <h2 className="mb-3">
                    15k <sub>/yr</sub>
                  </h2>
                </div>
                <ul>
                  <li>
                    <FaCheck />
                    Live Classes, Recordings, Study Material, Test Series Portal
                  </li>
                  <li>
                    <FaCheck />
                    Unlimited ID Creation
                  </li>
                  <li>
                    <FaCheck />
                    Push Notification
                  </li>
                  {/* <li>
                    <Fa />
                   Whatsapp Integration
                  </li> */}
                  <li>
                    <FaCheck />
                    Web Portal
                  </li>
                  <li>
                    <FaCheck/>
                    Server Charges(4% + GST + Payment Gateway)
                  </li>
                  <li >
                    <FaCheck />
                    Website Hosting(1 year free)
                  </li>
                  <li >
                    <FaCheck />
                    Android & IOS Developer Account
                  </li>
                  <li >
                    <FaCheck />
                   Domain
                  </li>
                </ul>
                <Link
                  className="btn btn-border-gray border-radius-0 w-100"
                  to="#"
                >
                  Started
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing-inner price-active">
                <div className="price-header text-center mb-lg-4">
                  <h5>Pro</h5>
                  <h2 className="mb-3">
                    20k <sub>/yr</sub>
                  </h2>
                </div>
                <ul>
                  <li>
                    <FaCheck />
                    Live Classes, Recordings, Study Material, Test Series Portal
                  </li>
                  <li>
                    <FaCheck />
                    Unlimited ID Creation
                  </li>
                  <li>
                    <FaCheck />
                    Push Notification
                  </li>
                  <li>
                    <FaCheck />
                   Whatsapp Integration
                  </li>
                  <li>
                    <FaCheck />
                    Web Portal
                  </li>
                  <li>
                    <FaCheck/>
                    Server Charges After 50 Student Ids(4% + GST + Payment Gateway)
                  </li>
                  <li >
                    <FaCheck />
                    Website Hosting(1 year free)
                  </li>
                  <li >
                    <FaCheck />
                    Android & IOS Developer Account
                  </li>
                  <li >
                    <FaCheck />
                   Domain
                  </li>
                </ul>
                <Link
                  className="btn btn-border-gray border-radius-0 w-100"
                  to="#"
                >
                  Started
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing-inner">
                <div className="price-header text-center mb-lg-4">
                  <h5>Expert</h5>
                  <h2 className="mb-3">
                    1 L <sub>/yr</sub>
                  </h2>
                </div>
                <ul>
                  <li>
                    <FaCheck />
                    Live Classes, Recordings, Study Material, Test Series Portal
                  </li>
                  <li>
                    <FaCheck />
                    Unlimited ID Creation
                  </li>
                  <li>
                    <FaCheck />
                    Push Notification
                  </li>
                  <li>
                    <FaCheck />
                   Whatsapp Integration
                  </li>
                  <li>
                    <FaCheck />
                    Web Portal
                  </li>
                  <li>
                    <FaCheck/>
                    Only Payment Gateway Charges
                  </li>
                  <li >
                    <FaCheck />
                    Website Hosting(1 year free)
                  </li>
                  <li >
                    <FaCheck />
                    Android & IOS Developer Account
                  </li>
                  <li >
                    <FaCheck />
                   Domain
                  </li>
                </ul>
                <Link
                  className="btn btn-border-gray border-radius-0 w-100"
                  to="#"
                >
                  Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Area Two start */}
    </>
  );
};

export default PricingAreaTwo;
