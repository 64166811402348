import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import axios from "axios";
import { toast, Toaster } from 'react-hot-toast';
const ContactMain = () => {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [success, setSuccess] = useState(false); // Success state

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axios.post("/send", formData); // Ensure the backend URL is correct
      setIsLoading(false);
      setSuccess(true);
      setFormData({
        name: "",
        contact: "",
        email: "",
        message: "",
      });
    } catch (error) {
      setIsLoading(false);
      setError("Error sending message. Please try again later.");
      console.error(error);
    }
  };

  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position="bottom-center" reverseOrder={false} />
      <>
        {/* contact area start */}
        <div className="contact-area pd-top-120 ">
          <div className="container">
            <div className="contact-page-inner bg-gray">
              <div className="section-title mb-4 pb-2">
                <h2 className="title">Direct contact us? </h2>
                {/* <p className="content mb-0">
                  For your car we will do everything advice, repairs and
                  maintenance. We are the some preferred choice by many car
                  owners because our experience and knowledge is selfe
                  vident.For your car we will do som everything.
                </p> */}

              </div>
              {success && (
                <div className="alert alert-success" role="alert">
                  Message sent successfully!
                </div>
              )}

              {/* Error Message */}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <form className="mt-4" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter Your Name."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        
                        placeholder="Enter Your Email."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="subject"
                        name="contact"
                        type="text"
                        value={formData.contact}
                        onChange={handleChange}
                        placeholder="Enter Your Contact."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-input-inner">
                      <textarea
                        name="message"
                        id="massage"
                        cols="1"
                        rows="5"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Enter Your Massage ..."
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                  <button
                      type="submit"
                      className="btn btn-base border-radius-5"
                      disabled={isLoading}
                    >
                      {isLoading ? "Sending..." : "Contact Us"} <FaPlus />
                    </button>
                    {/* <button
                      type="submit"
                      className="btn btn-base border-radius-5"
                    >
                      Send Message
                    </button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* contact area end */}
        {/* contact list start */}
        <div className="contact-page-list">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/img/icon/13.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5>Contacts us</h5>
                    <h6>(+91) 7015063016</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/img/icon/14.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5>Your Email</h5>
                    <h6>divinfosystem36@gmail.com</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/img/icon/15.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5>Location</h5>
                    <h6>Plot No. 10, Punjab Kesari Building, 1st Floor, Sector 25 D, Chandigarh</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* contact list start */}
        {/* map start */}
        <div className="contact-g-map pd-top-100">
          <iframe
            src="https://maps.google.com/maps?q=DIV%20INFO%20SYSTEM%20-%20Your%20Growth%20Is%20Our%20Passion&t=m&z=14&output=embed&iwloc=near" title="new title"
          />
        </div>
        {/* map end */}
      </>

      {/* ================= Contact Main End =================*/}
    </>
  );
};

export default ContactMain;
