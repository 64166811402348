import React from 'react';
import { Link } from 'react-router-dom';
const ServiceAreaGroupFour = () => {
  return (
    <>
      {/* service area start */}
      <div className="service-area bg-relative pd-top-120">
        <div className="container">
          <div className="row">
          <div className='section-title text-center'>
                
                <h2 className='title'>
                  Why <span>Choose</span> Us?
                </h2>
              </div>
            <div className="col-lg-6 pe-5">
           
              <div className="single-service-inner-3 single-service-inner-3-left">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/support.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    24/7 Support
                  </h5>
                  <p className="mb-0">
                  We provide 24/7 instant support to ensure your business operations run smoothly without any interruptions. 
                  </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-left">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/solution.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    Custom Solutions
                  </h5>
                  <p className="mb-0">
                  We offer custom solutions for web, mobile, and desktop platforms, specifically designed to meet the needs of your business.

                  </p>
                </div>
              </div>
              {/* <div className="single-service-inner-3 single-service-inner-3-left mb-0">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/9.svg" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    <Link to="/service-details">Affordable Pricing</Link>
                  </h5>
                  <p className="mb-0">
                  Our pricing is more affordable, ensuring that you receive high-quality services without any interruption. 
                  </p>
                </div>
              </div> */}
            </div>
            <div className="col-lg-6 ps-md-5 pe-5 pe-md-0 mt-4 mt-md-0">
              <div className="single-service-inner-3 single-service-inner-3-right">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/strategy.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    Web Strategy Support
                  </h5>
                  <p className="mb-0">
                  We offer expert guidance to help your business develop and implement effective web strategies.
                  </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-right">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/pricing.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    Affordable Pricing
                  </h5>
                  <p className="mb-0">
                  Our pricing is more affordable, ensuring that you receive high-quality services without any interruption. 
                 
                  </p>
                </div>
              </div>
              {/* <div className="single-service-inner-3 single-service-inner-3-right mb-0">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/12.svg" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    <Link to="/service-details">Smart Vision</Link>
                  </h5>
                  <p className="mb-0">
                    Aliquam eros justo, posuere loborti inh thi viverra coin
                    design here laoreet min ouimatti posuere lorem
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* service area end */}
    </>
  );
};

export default ServiceAreaGroupFour;
