import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";

const SeoServiceArea = () => {
  return (
    <>
      {/*=================== service area five start ===================*/}
      <div className="service-area service-area_5 bg-gray bg-relative pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6 className="sub-title">Our Best Service</h6>
                <h2 className="title">
               <span>Benefits</span>  of Choosing <span>Div Info System</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row custom-no-gutter">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-3 single-service-inner-3-left">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/traffic.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    More Traffic to the Website
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  Major benefit of SEO is its ability to drive more organic traffic & sales to your website. .  </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-left">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/cost.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Cost-Effective
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  SEO is one of the most cost-effective marketing strategy. SEO helps you gain long-term results without ongoing ad costs. </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-left mb-0">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/brand.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Brand Awareness & Trust
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  As your website rank higher in search engine results, more people become aware               of your brand. </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 bg-blue-right d-lg-inline-block d-none">
              <div className="service-thumb service-middle-section align-self-end">
                <img src="assets/img/service-icon/seo_cover.png" alt="img" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-3 single-service-inner-3-right">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/local.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Local Traffic for Local Business
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  For businesses that serve a specific geographic area, local SEO is an important feature. It drives more relevant traffic.</p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-right">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/authority.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Better Website Authority
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  SEO improves your website’s overall authority by making it more reliable, informative, and trusted by search engines.  </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-right mb-0">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/opoortunity.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    More Business Opportunities
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  By increasing your online visibility and traffic, SEO opens up new business opportunities. </p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default SeoServiceArea;
