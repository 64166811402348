let digitalServiceList = [
    {
      title: "Search Engine Optimization (SEO)",
      des: "Enhance your online presence and increase organic traffic with our strategic SEO services. Our experts optimize your website, conduct thorough keyword research, and implement best practices to improve search engine rankings. ",
      img: "assets/img/service-icon/seo.png",
    },
    {
      title: "Social Media Marketing",
      des: "Engage and connect with your audience on popular social media platforms. Our social media experts craft compelling content, manage campaigns, and analyze performance to build a strong online community around your brand.",
      img: "assets/img/service-icon/socialmedia.png",
    },
    {
      title: "Pay-Per-Click (PPC) Advertising",
      des: "Drive instant, targeted traffic to your website with our PPC advertising services. We create and manage effective campaigns on platforms like Google Ads and Bing Ads, ensuring maximum ROI for your ad spend.",
      img: "assets/img/service-icon/ppc.png",
    },
    {
      title: "Content Marketing",
      des: "Quality content is key to online success. Our content marketing services encompass the creation of engaging and relevant content, including blog posts, articles, infographics, and more, to establish your authority in the industry.",
      img: "assets/img/service-icon/content.png",
    },
    {
      title: "Email Marketing",
      des: "Nurture leads and maintain customer engagement through strategic email campaigns. We design, execute, and optimize email marketing strategies that resonate with your audience and drive conversions.",
      img: "assets/img/service-icon/email_marketing.png",
    },
  
    {
      title: "Website Design and Development",
      des: "Ensure a user-friendly and visually appealing online presence. Our web design and development services focus on creating responsive, SEO-friendly websites that provide a seamless user experience.",
      img: "assets/img/service-icon/web_design.png",
    },

    {
        title: "Analytics and Reporting",
        des: "Data-driven decision-making is crucial. We provide detailed analytics and regular reports, allowing you to track the performance of your digital marketing campaigns and make informed decisions for continuous improvement.",
        img: "assets/img/service-icon/analytics.png",
      },
      {
        title: "Reputation Management",
        des: "Protect and enhance your online reputation. Our reputation management services monitor online mentions, manage reviews, and implement strategies to build and maintain a positive brand image.",
        img: "assets/img/service-icon/reputation.png",
      },
      {
        title: "E-commerce Marketing",
        des: "Ensure a user-friendly and visually appealing online presence. Our web design and development services focus on creating responsive, SEO-friendly websites that provide a seamless user experience.",
        img: "assets/img/service-icon/ecommercem.png",
      },
  ];
  
  export default digitalServiceList;
  