let serviceList = [
  {
    title: "",
    des: "",
    img: "assets/img/service-icon/Cloud_classes_logo.png",
  },
  // {
  //   title: "Content Marketing",
  //   des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
  //   img: "assets/img/service-icon/2.png",
  // },
  // {
  //   title: "E-commerce Solutions",
  //   des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
  //   img: "assets/img/service-icon/3.png",
  // },
  // {
  //   title: "Content Marketing",
  //   des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
  //   img: "assets/img/service-icon/2.png",
  // },
  // {
  //   title: "Mobile App Development",
  //   des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
  //   img: "assets/img/service-icon/1.png",
  // },

  // {
  //   title: "E-commerce Solutions",
  //   des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
  //   img: "assets/img/service-icon/3.png",
  // },
];

export default serviceList;
