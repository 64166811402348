import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import PricingAreaTwo from "../components/PricingAreaTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";
import NavbarThree from "../components/NavbarThree";
import FooterOne from "../components/FooterOne";
import CaseStudyAreaThree from "../components/CaseStudyAreaThree";
import BannerThree from "../components/BannerThree";
import ContactAreaFour from "../components/ContactAreaFour";

const HomeTwo = () => {
  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarThree />

      {/* Banner Two */}
      {/* <BannerTwo /> */}
      <BannerThree />


      {/* About Area Two */}
      <AboutAreaTwo />

      {/* About Area Two */}
      <ServiceAreaTwo />
      <CaseStudyAreaThree />
      {/* Pricing Area Two */}
      <PricingAreaTwo />

      {/* Counter Area Two */}
      {/* <CounterAreaTwo /> */}

      {/* Contact Area Two */}
      {/* <ContactAreaTwo /> */}
      <ContactAreaFour/>

      {/* Work Process Two */}
      {/* <WorkProcessTwo /> */}

      {/* Testimonial One */}
      {/* <TestimonialOne /> */}

      {/* Blog Area Two */}
      {/* <BlogAreaTwo /> */}

      {/* Footer Two */}
      <FooterOne />
    </>
  );
};

export default HomeTwo;
