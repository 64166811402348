import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBarThree from "../components/NavbarThree";
import DigitalServiceDetailsArea from "../components/DigitalServiceDetailsArea";
import DigitalServiceArea from "../components/DigitalServiceArea";
import DigitalTechArea from "../components/DigitalTechArea";

const DigitalServiceDetails = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarThree />

      {/* Navigation Bar */}
      <Breadcrumb title={"Digital Marketing"} />

      {/* Service Details Area */}
      <DigitalServiceDetailsArea />
      {/* <DigitalTechArea/> */}
      <DigitalServiceArea/>

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default DigitalServiceDetails;
