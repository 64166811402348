import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBarThree from "../components/NavbarThree";
import WebServiceDetailsArea from "../components/WebServiceDetailsArea";
import WorkProcessFive from "../components/WorkProcessFive";
import WebTechArea from "../components/WebTechArea";

const WebServiceDetails = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarThree />

      {/* Navigation Bar */}
      <Breadcrumb title={" Web Design & Development Services"} />

      {/* Service Details Area */}
      <WebServiceDetailsArea />
      {/* <WebTechArea/> */}
      {/* <WorkProcessFive /> */}
      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default WebServiceDetails;
