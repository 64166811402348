import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";

const DigitalServiceArea = () => {
  return (
    <>
      {/*=================== service area five start ===================*/}
      <div className="service-area service-area_5 bg-gray bg-relative pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6 className="sub-title">Our Best Service</h6>
                <h2 className="title">
               <span>Benefits</span>  of Choosing <span>Div Info System</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row custom-no-gutter">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-3 single-service-inner-3-left">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/outcomes.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Tangible Outcomes
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  At Div Info System, every action we take on digital platforms is instantly recorded and easily accessible.   </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-left">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/affordable.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Affordable Solutions
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  For small businesses, going up against big industry players in marketing can be tough because of their huge budgets. </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-left mb-0">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/focusmarket.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Focused Marketing
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  Putting an ad in a big newspaper might get seen by a lot of people, but how many of them are actually interested in what you offer? </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 bg-blue-right d-lg-inline-block d-none">
              <div className="service-thumb service-middle-section align-self-end">
                <img src="assets/img/service-icon/digital1.png" alt="img" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner-3 single-service-inner-3-right">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/better.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Better Results
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  When it comes to converting potential customers, digital marketing outshines traditional methods by a mile.</p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-right">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/boosting.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Boosting Your Brand
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  People often buy a product because of the brand rather than anything else. </p>
                </div>
              </div>
              <div className="single-service-inner-3 single-service-inner-3-right mb-0">
                <div className="thumb">
                  <div className="thumb-inner">
                    <img src="assets/img/service-icon/notice.png" alt="img" />
                  </div>
                </div>
                <div className="details">
                  <h5 className="mb-3">
                    {/* <Link to="/service-details"> */}
                    Get Noticed Online
                    {/* </Link> */}
                  </h5>
                  <p className="mb-0">
                  In today’s digital world, it’s essential for businesses to be easily found on the internet. </p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default DigitalServiceArea;
