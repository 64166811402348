import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const ServiceAreaGroupFive = () => {
  return (
    <>
      {/* service area start */}
      <div className="service-area bg-relative pd-top-120">
        <div className="container">
        <div className='section-title text-center'>
             
                <h2 className='title'>What we make?</h2>
                </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/service-icon/ar.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    {/* <Link to="/service-details"> */}
                    AR & VR Apps
                    {/* </Link> */}
                  </h5>
                  <p>
                  We added cool new things like AR and VR to make the apps more advanced, with features like 2D and 3D projections and AR-powered navigation.
                  </p>
                  {/* <Link className="read-more-text" to="/service-details">
                    Touch More <FaPlus />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/service-icon/blockchain.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    {/* <Link to="/service-details"> */}
                    Blockchain
                    {/* </Link> */}
                  </h5>
                  <p>
                  Our Blockchain developers create and set up special decentralized systems. They also help you add Blockchain technology to the apps and software you already have. 
                  </p>
                  {/* <Link className="read-more-text" to="/service-details">
                    Touch More <FaPlus />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/service-icon/ai.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    {/* <Link to="/service-details"> */}
                    AI-Based Mobile Apps
                    {/* </Link> */}
                  </h5>
                  <p>
                  We put different smart features in the apps using AI, so it makes the customer experience better. 
                  </p>
                  {/* <Link className="read-more-text" to="/service-details">
                    Touch More <FaPlus />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/service-icon/bigdata.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/service-details">Big Data And Analysis Apps</Link>
                  </h5>
                  <p>
                  Our apps use big data and analytics technology to help you make smarter business choices. </p>
                  {/* <Link className="read-more-text" to="/service-details">
                    Touch More <FaPlus />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/service-icon/iot.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    {/* <Link to="/service-details"> */}
                    IoT Apps
                    {/* </Link> */}
                  </h5>
                  <p>
                  Div Info System has a lot of experience in both internet technology and mobility. We can make an app that uses IoT and works on all devices.  </p>
                  {/* <Link className="read-more-text" to="/service-details">
                    Touch More <FaPlus />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner style-3">
                <div className="thumb">
                  <img src="assets/img/service-icon/ml.png" alt="img" />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/service-details">
                    Machine Learning Apps
                    </Link>
                  </h5>
                  <p>
                  Our developers are really good at making apps that use ML technology for different things like recognizing patterns or identifying visuals.  </p>
                  {/* <Link className="read-more-text" to="/service-details">
                    Touch More <FaPlus />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service area end */}
    </>
  );
};

export default ServiceAreaGroupFive;
