import React from 'react';
import { FaLink } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ProjectAreaThree = () => {
  return (
    <div className="project-section project-section_3 pd-bottom-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h6 className="color-base bg-none mb-3">Our Tech Stack</h6>
              <h2 className="title">
              Technology We Use For Development
              </h2>
            </div>
          </div>
        </div>
        <nav className="text-center">
          <div
            className="nav nav-tabs project-nav-tab style-2 d-inline-flex mb-5"
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link active"
              id="nav-tabs1-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-tabs1"
              type="button"
              role="tab"
              aria-controls="nav-tabs1"
              aria-selected="true"
            >
             IOS
            </button>
            <button
              className="nav-link"
              id="nav-tabs2-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-tabs2"
              type="button"
              role="tab"
              aria-controls="nav-tabs2"
              aria-selected="false"
            >
              Android
            </button>
            <button
              className="nav-link"
              id="nav-tabs3-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-tabs3"
              type="button"
              role="tab"
              aria-controls="nav-tabs3"
              aria-selected="false"
            >
              Cross-Platform
            </button>
            <button
              className="nav-link"
              id="nav-tabs4-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-tabs4"
              type="button"
              role="tab"
              aria-controls="nav-tabs4"
              aria-selected="false"
            >
              Cloud App 
            </button>
          </div>
        </nav>
      </div>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-tabs1"
          role="tabpanel"
          aria-labelledby="nav-tabs1-tab"
        >
          <div className="row gx-md-0">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/swift.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Swift</h5>
                  <p>
                  Apple's programming language for iOS, macOS, optimized for performance and safety.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/flutter.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Flutter</h5>
                  <p>
                  Cross-platform UI toolkit by Google, uses Dart, for building mobile applications.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/swiftui.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Swift UI</h5>
                  <p>
                  Apple's declarative framework for building user interfaces across all Apple platforms.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/ionic.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Ionic</h5>
                  <p>
                  Hybrid mobile app framework using web technologies like HTML, CSS, JavaScript.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/native.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>React Native</h5>
                  <p>
                  Facebook's open-source framework for building mobile apps using JavaScript and React.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/objc.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Objective-C</h5>
                  <p>
                  Apple's older programming language for iOS/macOS development, superseded by Swift, based on C.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-tabs2"
          role="tabpanel"
          aria-labelledby="nav-tabs2-tab"
        >
          <div className="row gx-md-0">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/kotlin.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Kotlin</h5>
                  <p>
                  Modern programming language for Android development, interoperable with Java, concise syntax.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/native.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>React Native</h5>
                  <p>
                  Facebook's open-source framework for building mobile apps using JavaScript and React.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/flutter.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Flutter</h5>
                  <p>
                  Cross-platform UI toolkit by Google, uses Dart, for building mobile applications.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/java.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Java</h5>
                  <p>
                  Popular, object-oriented programming language used for Android app and enterprise development.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/jquery.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>jQuery Mobile</h5>
                  <p>
                  A touch-optimized framework for building cross-platform mobile web apps using HTML5 and jQuery.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/angularui.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Angular Mobile UI</h5>
                  <p>
                  A framework combining AngularJS and Bootstrap to create responsive, mobile-friendly web applications.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-tabs3"
          role="tabpanel"
          aria-labelledby="nav-tabs3-tab"
        >
          <div className="row gx-md-0">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/flutter.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Flutter</h5>
                  <p>
                  Cross-platform UI toolkit by Google, uses Dart, for building mobile applications.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/native.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>React Native</h5>
                  <p>
                  Facebook's open-source framework for building mobile apps using JavaScript and React.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/xamerin.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Xamarin</h5>
                  <p>
                  Microsoft's cross-platform development framework using C# for building Android and iOS apps.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/jquery.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>jQuery Mobile</h5>
                  <p>
                  A touch-optimized framework for building cross-platform mobile web apps using HTML5 and jQuery.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/senchatouch.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Sencha Touch</h5>
                  <p>
                  Framework for building cross-platform mobile web apps with HTML5, JavaScript, and CSS3.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/ionic.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Ionic</h5>
                  <p>
                  Hybrid mobile app framework using web technologies like HTML, CSS, JavaScript.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-tabs4"
          role="tabpanel"
          aria-labelledby="nav-tabs4-tab"
        >
          <div className="row gx-md-0">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/nativescript.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>NativeScript</h5>
                  <p>
                  An open-source framework for building native mobile apps using JavaScript, TypeScript, or Angular. </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/jetpack.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>JetPack</h5>
                  <p>
                  Android development suite offering libraries for architecture, UI components, and performance.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/gradle.png" alt="img" />
                <div className="details text-start">
                  {/* <p>Hardware,Tech Solve</p> */}
                  <h5>Gradle</h5>
                  <p>
                  Build automation tool used in Android projects, handles dependencies, and project configurations.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/java.png" alt="img" />
                <div className="details text-start">
                <h5>Java</h5>
                  <p>
                  Popular, object-oriented programming language used for Android app and enterprise development.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/senchatouch.png" alt="img" />
                <div className="details text-start">
                <h5>Sencha Touch</h5>
                  <p>
                  Framework for building cross-platform mobile web apps with HTML5, JavaScript, and CSS3.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="single-project-inner style-3 text-start">
                <img src="assets/img/home-7/ionic.png" alt="img" />
                <div className="details text-start">
                <h5>Ionic</h5>
                  <p>
                  Hybrid mobile app framework using web technologies like HTML, CSS, JavaScript.
                  </p>
                  <div className="link-wrap text-center">
                    <Link to="#">
                      <FaLink />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaThree;
