let seoServiceList = [
    {
      title: "Voice SEO services",
      des: "Our Voice SEO services optimize your website for voice searches, helping you appear in the results when users ask for solutions through their smart speakers and mobile devices. With the rise of voice-activated devices, more people are using voice search to find information. Voice SEO connects your business with relevant audience. ",
      img: "assets/img/service-icon/voice.png",
    },
    {
      title: "YouTube SEO services",
      des: "YouTube is the second-largest search engine worldwide. Our YouTube SEO services are available to enhance your channel’s reach and improve video rankings. We optimize video titles, descriptions, proper keyword integration and thumbnail design. Optimizing your YouTube presence leads to better engagement and more views.",
      img: "assets/img/service-icon/youtube.png",
    },
    {
      title: "Local SEO services",
      des: "Our Local SEO services are available to increase your visibility within local search results and Google Maps. Local SEO ensures your business is easy to find, helping you attract more foot traffic, phone calls, and leads from nearby locations. For businesses that rely on local customers, optimizing for local searches is very important.",
      img: "assets/img/service-icon/localseo.png",
    },
    {
      title: "On-Page SEO",
      des: "Our On-page SEO is about making sure your website’s content, structure, and metadata are well optimized for search engines. By optimizing these on-page factors, your website becomes more visible in search result pages which can lead to higher user engagement and more potential customers.",
      img: "assets/img/service-icon/onpage.png",
    },
    {
      title: "Off Page SEO",
      des: "Our Off-Page SEO services focus on building high-quality backlinks and improving your website’s trust and domain authority. It boosts your website’s visibility, helping it rank higher in search results, which attracts more visitors. We use strategies like guest posting, influencer outreach, social media signals, link-building and more to attract customers.",
      img: "assets/img/service-icon/offpage.png",
    },
  
    {
      title: "Technical SEO",
      des: "Technical SEO is the backend of your website, ensuring that it is optimized for search engine crawlers. We focus on improving site speed, fixing broken links, enhancing mobile responsiveness, and ensuring your website follows the latest SEO best practices. By removing technical errors, your website becomes easier for search engines to index and rank.",
      img: "assets/img/service-icon/technical.png",
    },
  ];
  
  export default seoServiceList;
  