let uiServiceList = [
    {
      title: "Customized Web Design Services",
      des: "We make special website designs for our clients. Our team of designers, who know a lot about making websites look good and easy to use, create a sample version of your project with the best design ideas. ",
      img: "assets/img/service-icon/customized.png",
    },
    {
      title: "Interface Design for Mobile App",
      des: "The success of a mobile app mostly relies on how easy it is for people to use. Our really good designers create designs that connect well with users and make sure they like using the app and keep using it.",
      img: "assets/img/service-icon/interface.png",
    },
    {
      title: "Front End Development",
      des: "We're a top company in making apps and websites look and work well. We make sure our designs work on different devices, and our designers write code that helps your business do better.",
      img: "assets/img/service-icon/front.png",
    },
    {
      title: "Web Design Services",
      des: "We provide businesses with really good website design services to easily connect with customers online. We use our great experience in website design to help your business reach more people.",
      img: "assets/img/service-icon/2.png",
    },
    {
      title: "E-Commerce App Design",
      des: "We help our clients make online stores that are fun to use and have lots of cool features. This makes it easy for people to shop, and more people stay interested and involved.",
      img: "assets/img/service-icon/ecommerce.png",
    },
  
    {
      title: "iOS and Android App Designs",
      des: "Our designers make sure the iPhone and Android apps look and feel really nice. They take care to create a smooth experience for both types of apps. ",
      img: "assets/img/service-icon/app.png",
    },
  ];
  
  export default uiServiceList;
  