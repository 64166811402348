import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBarThree from "../components/NavbarThree";
import UiServiceDetailsArea from "../components/UiServiceDetailsArea";
import UiServiceArea from "../components/UiServiceArea";


const UiServiceDetails = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarThree />

      {/* Navigation Bar */}
      <Breadcrumb title={"UI/UX Design"} />

      {/* Service Details Area */}
      <UiServiceDetailsArea />
<UiServiceArea/>

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default UiServiceDetails;
