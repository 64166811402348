let uiServiceList = [
    {
      title: "Frontend Development",
      des: "We concentrate on making your web app look good and easy to use. We do this by studying your target audience, understanding your brand, and using industry-approved methods. Our designers and developers use their skills and creativity to ensure that using your web app is always a smooth experience. The front part of your web app, which includes cool animations and effects, won't slow down how fast it works.",
      img: "assets/img/service-icon/front.png",
    },
    {
      title: "Backend Development",
      des: "Our group of backend developers builds strong and safe web applications using a strong structure that can handle your business's future growth. Additionally, you can connect our created backends with other systems and services from outside companies. Thanks to our advanced data structures, businesses can get useful insights to help them succeed.",
      img: "assets/img/service-icon/backend.png",
    },
    {
      title: "eCommerce & CMS Development",
      des: "We provide special services to make your online store and website better. Our services include creating online stores, moving them to a new platform, and managing them. We also make special features for managing content on your website. Whether it's adding new functions or improving the whole system, we've got you covered.",
      img: "assets/img/service-icon/ecommerce.png",
    },
    {
      title: "ERP Solutions",
      des: "Our skilled team of developers can either build strong ERP systems from the beginning or modify your current ones by adding the latest features. With Div Info System, you can get personalized ERP solutions that match what your business needs. This includes moving to a new system, setting it up, adding new features, and more, all based on what your business requires.",
      img: "assets/img/service-icon/erp.png",
    },
    {
      title: "SaaS Development Services",
      des: "Take advantage of our top-notch SaaS development services. We provide complete solutions, including advice at the beginning, creating the software, and making sure it works well even after it's finished. With Div Info System, you can create different types of software for your business, such as for managing a company, embedded systems, control systems, and more, based on what your business requires.",
      img: "assets/img/service-icon/saas.png",
    },
  
    {
      title: "Web Application Development",
      des: "We are really good at making various kinds of websites that can meet your specific needs, whether it's connecting with your customers, partners, or employees. We make websites for different purposes like customer service, online stores (for regular customers and businesses), platforms for suppliers, healthcare information for patients, online learning sites, and more.",
      img: "assets/img/service-icon/webapp.png",
    },

    {
        title: "QA & Testing",
        des: "We always make sure to test our work at every step of creating a product, from the beginning to even after it's done. Our main goal is to give the client a product that's good in every way – it works well, it's fast, easy to use, and secure, among other important things.",
        img: "assets/img/service-icon/qa.png",
      },
      {
        title: "Cloud migration",
        des: "We can move your current website from where it is now to the internet cloud. If necessary, we'll also make any needed updates to the old website's structure, design, and features to make sure it works well in its new home.",
        img: "assets/img/service-icon/cloudd.png",
      },
      {
        title: "Legacy Modernization",
        des: "We update and improve your old website by redesigning its structure, cleaning up its code, moving it to more advanced systems, adding new features, and making its look and feel more modern.",
        img: "assets/img/service-icon/legacy.png",
      },
  ];
  
  export default uiServiceList;
  