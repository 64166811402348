import React from "react";
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import uiServiceList from "../scripts/uiServiceList";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import TrackVisibility from 'react-on-screen';
import CountUp from 'react-countup';

const UiServiceDetailsArea = () => {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className="service-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar service-sidebar">
                <div className="widget widget_catagory">
                  <h5 className="widget-title">
                    <FaArrowRight /> All Service lists
                  </h5>
                  <ul className="catagory-items">
                    <li>
                      <Link to="/ui-ux-service-details">
                        {" "}
                        UI/UX Design Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/web-design-service-details">
                        {" "}
                        Web Design & Development Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/mobile-app-service-details">
                        {" "}
                        Mobile App Development (Android & iOS)
                      </Link>
                    </li>
                    <li>
                      <Link to="/digital-marketing-service-details">
                        {" "}
                        Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <Link to="/seo-service-details">
                        SEO (Search Engine Optimization)
                      </Link>
                    </li>
                    <li>
                      {/* <Link to="/email-marketing-service-details">
                        Email Marketing
                      </Link> */}
                    </li>
                  </ul>
                </div>
                {/* <div className="widget widget_author text-center">
                  <div className="thumb">
                    <img src="assets/img/about/9.png" alt="img" />
                  </div>
                  <div className="details">
                    <Link className="btn btn-base border-radius-5" to="#">
                      Discover our company +
                    </Link>
                  </div>
                </div>
             */}
              </div>
            </div>
            <div className="col-lg-8">
              <div className="thumb">
                <img src="assets/img/service/uiux.jpg" alt="img" />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="details">
                    {/* <h4>
                    Div Info System provides a range of UI/UX web design services
                    </h4>
                    <p>
                    Div Info System is the best company for making websites look and work great. We have a team of talented designers who know all the latest tools for making websites awesome. Our designers can do a lot of different things to make your website just the way you want it. </p>
                     */}
                    <div className="service-area bg-relative ">
                      <img
                        className="position-bottom-left top_image_bounce"
                        src="assets/img/icon/4.png"
                        alt="img"
                      />
                      <div className="container">
                        <div className="row">
                          {uiServiceList.map((data, index) => (
                            <div className="col-lg-4 col-md-6" key={index}>
                              <div className="single-service-inner text-center">
                                <div className="thumb">
                                  <img src={data.img} alt="img" />
                                </div>
                                <div className="details">
                                  <h5>
                                    {/* <Link to="/service-details"> */}
                                      {data.title}
                                    {/* </Link> */}
                                  </h5>
                                  <p>{data.des}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* <div className='bg-base client-border-radius p-xl-5 p-3 mt-5'>
            <div className='client-slider'>
              <Marquee gradient={false}>
                <div className='thumb'>
                  <img src='assets/img/client/1.svg' alt='img' />
                  
                </div>
                <div className='thumb'>
                  <img src='assets/img/client/2.svg' alt='img' />
                </div>
                <div className='thumb'>
                  <img src='assets/img/client/3.svg' alt='img' />
                </div>
                <div className='thumb'>
                  <img src='assets/img/client/4.svg' alt='img' />
                </div>
                <div className='thumb'>
                  <img src='assets/img/client/5.svg' alt='img' />
                </div>
                <div className='thumb'>
                  <img src='assets/img/client/6.svg' alt='img' />
                </div>
              </Marquee>
            </div>
          </div> */}
          {/* <div className="container pd-top-100">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 wow animated fadeInLeft"
                data-wow-duration="1.5s"
                data-wow-delay="0.3s"
              >
                <img
                  className="main-img"
                  src="assets/img/home-8/7.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-wow-duration="1.5s"
              data-wow-delay="0.3s"
            >
              <div className="section-title mt-5 mt-lg-0 ">
                <h2 className="title">Are You Ready to Begin?</h2>
                <p className="content mb-4 mb-xl-5">
                With more than 7+ years of experience in the development field, Div Info System is India’s most trusted company for UI/UX design and development.The company has a successful history of helping clients worldwide with top-notch logo design, graphic design, and web design services as part of its UI/UX design offerings.  
                </p>
                <div className="box-shadow p-4 pb-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="single-counter-inner media">
                        <div className="thumb media-left pe-4">
                          <img src="assets/img/home-8/3.png" alt="img" />
                        </div>
                        <div className="media-body">
                          <h2 className="mb-1">
                            <TrackVisibility once>
                              {({ isVisible }) =>
                                isVisible && (
                                  <span className="counter">
                                    <CountUp delay={0} start={0} end={100} /> +
                                  </span>
                                )
                              }
                            </TrackVisibility>
                          </h2>
                          <p className="mb-0">Project Done</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-counter-inner media">
                        <div className="thumb media-left pe-4">
                          <img src="assets/img/home-8/4.png" alt="img" />
                        </div>
                        <div className="media-body">
                          <h2 className="mb-1">
                            <TrackVisibility once>
                              {({ isVisible }) =>
                                isVisible && (
                                  <span className="counter">
                                    <CountUp delay={0} start={0} end={1300} /> +
                                  </span>
                                )
                              }
                            </TrackVisibility>
                          </h2>
                          <p className="mb-0">Satisfied Customers</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-counter-inner media">
                        <div className="thumb media-left pe-4">
                          <img src="assets/img/home-8/5.png" alt="img" />
                        </div>
                        <div className="media-body">
                          <h2 className="mb-1">
                            <TrackVisibility once>
                              {({ isVisible }) =>
                                isVisible && (
                                  <span className="counter">
                                    <CountUp delay={0} start={0} end={7} /> +
                                  </span>
                                )
                              }
                            </TrackVisibility>
                          </h2>
                          <p className="mb-0">Glorious Years</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-counter-inner media">
                        <div className="thumb media-left pe-4">
                          <img src="assets/img/home-8/6.png" alt="img" />
                        </div>
                        <div className="media-body">
                          <h2 className="mb-1">
                            <TrackVisibility once>
                              {({ isVisible }) =>
                                isVisible && (
                                  <span className="counter">
                                    <CountUp delay={0} start={0} end={98} /> +
                                  </span>
                                )
                              }
                            </TrackVisibility>
                          </h2>
                          <p className="mb-0">Active Clients</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      
                    </div>
                    {/* <h4>Design Excellence in Every Detail</h4> */}

                    {/* <div className="row">
                      <div className="col-md-6">
                        <ul className="single-list-inner style-check style-check mb-3">
                          <li>
                            <FaCheckCircle /> Visual Design & Branding
                          </li>
                          <li>
                            <FaCheckCircle /> Wireframing & Prototyping
                            companies
                          </li>
                          <li>
                            <FaCheckCircle /> Interaction Design
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="single-list-inner style-check style-check mb-3">
                          <li>
                            <FaCheckCircle /> Creating a Balanced and Nutritious
                          </li>
                          <li>
                            <FaCheckCircle /> User Research & Personas
                            companies
                          </li>
                          <li>
                            <FaCheckCircle /> Accessibility Design
                          </li>
                        </ul>
                      </div>
                    </div> */}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default UiServiceDetailsArea;
