import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div id="about" className='about-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 mb-4 mb-lg-0 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner'>
                <img
                  className='main-img'
                  src='assets/img/about/32.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
                <h6 className='sub-title'>ABOUT US</h6>
                <h2 className='title'>
                Best Online Classes App In India
                </h2>
                <p className='content mb-4'>
                Cloud Classes is the best online classes app in India that provides coaching institutes, tutors, and schools to take online classes. Cloud Classes provides an easy-to-use powerful platform to conduct online classes. Teachers can create and manage batches, schedule classes, and monitor student progress with ease.So, if you’re looking for a reliable and effective online learning platform, look no further than Cloud Classes. 
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Unlimited IDs for Teachers & Students 
                      </li>
                      <li>
                        <FaCheckCircle /> Social Media Integration
                      </li>
                    
                      <li>
                        <FaCheckCircle /> Marketing Support for Brand Building
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Your Own Branded Application
                      </li>
                      <li>
                        <FaCheckCircle /> Live / Online Classes with Recording
                      </li>
                      <li>
                        <FaCheckCircle /> Special Sections for Test Portal, Study Material, Assignments.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <p className='content'>
                  SaaS providers typically invest heavily in security measures
                  to protect customer data, such as encryption and multi-factor
                  authentication. However, it is important to research and
                  choose a reputable provider and also to ensure that
                </p> */}
                <Link className='btn btn-border-base' to='/contact'>
                  Discover More <FaPlus />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
