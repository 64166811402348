let mobileServiceList = [
    {
      title: "Custom Android App Development",
      des: "We make special mobile apps that match your business ideas. Trust our skilled developers who use a top-notch approach to meet your needs.",
      img: "assets/img/service-icon/custom.png",
    },
    {
      title: "App Development Consulting",
      des: "Connect with our special consulting services for mobile app development. We'll help you with the right strategy and skills to make your mobile app work well.",
      img: "assets/img/service-icon/appdev.png",
    },
    {
      title: "Wearable Apps Development",
      des: "Our professional engineers build apps for the new tech-wearables. You can get customized apps for Android, iOS, and similar platforms to target the right audience.",
      img: "assets/img/service-icon/wearable.png",
    },
    {
      title: "Hybrid Mobile App Development",
      des: "Trust us to make special hybrid apps that run well on both Android and iOS. This will make your business work better, save time, and cost less.",
      img: "assets/img/service-icon/hubrid.png",
    },
    {
      title: "Native Mobile App Development",
      des: "Div Info System creates strong native apps that are made to fit your specific needs. You get cool features, and you can make it bigger as needed.",
      img: "assets/img/service-icon/nativeapp.png",
    },
  
    {
      title: "Digital Transformation",
      des: "Get started with the latest technologies like AI, AR, and IoT to let your business reach the 9th cloud with the best functionality and changing trends.",
      img: "assets/img/service-icon/digital.png",
    },
  ];
  
  export default mobileServiceList;
  