import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBarThree from "../components/NavbarThree";
import EmailServiceDetailsArea from "../components/EmailServiceDetailsArea";

const EmailServiceDetails = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBarThree />

      {/* Navigation Bar */}
      <Breadcrumb title={"Email Marketing"} />

      {/* Service Details Area */}
      <EmailServiceDetailsArea />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default EmailServiceDetails;
